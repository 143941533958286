<template>
  <div class="grid crud-demo">
    <Loader v-model="loading" />
    <ContextMenu ref="menu" :model="items">
      <template #item="{item}">
           <a @click="triggerMenu(item)" class="p-menuitem-link" aria-haspopup="false" aria-expanded="true" role="menuitem" tabindex="0">
            <i :class="item.icon"></i>
            <span style="padding-left: 1rem;" class="p-menuitem-text">{{item.label}}</span>
            <span class="p-ink"></span>
          </a>
      </template>
    </ContextMenu>
    <div class="col-12">
      <div class="card">
        <Panel header="Cuenta Contable">
          <Toast />
        <BasicFormToolbar
        @new="openNew" @save="save" @refresh="refresh" @export="exportCSV" :uploadURL="uploadURL"
        @uploaded="uploaded"
        @beforeUpload="loading = true"
        :actions="['new','refresh','export','save', 'import']" />
        <div class="grid">
          <div class="col-4">
                <Fieldset legend="Cuentas">
                  <Tree :value="nodes" 
                  selectionMode="single" 
                  v-model:selectionKeys="selectedKey" 
                  @node-select="onNodeSelect" 
                  :metaKeySelection="false">
                  </Tree>
                </Fieldset>
          </div>
          <div class="col-8">
                <Fieldset legend="Informacion de Cuenta">
                  <div class="grid">
                    <div class="col-12">
                      <Fieldset legend="Datos Cuenta Padre">
                        <div class="p-fluid formgrid grid">
                          <FormInputText :disabled="true" wrapperClass="field col-6" label="Clave" 
                            v-model="parent.id_key"/>
                          <FormInputText :disabled="true" wrapperClass="field col-6" label="Nombre" 
                            v-model="parent.name"/>
                        </div>
                      </Fieldset>
                    </div> 
                   <div class="col-12">
                      <Fieldset legend="Datos Generales">
                        <div class="p-fluid formgrid grid">
                          <FormDropdown :disabled="parent.id_key"  wrapperClass="field col-6" label="Tipo Grupo Contable" 
                            v-model="entity.id_accounting_group_type" :options="groups_type" optionLabel="name" optionValue="id"
                            :valid="validate.validations.id_accounting_group_type" /> 
                            <FormDropdown :disabled="parent.id_key" wrapperClass="field col-6" label="Grupo Contable" 
                            v-model="entity.id_accounting_group" :options="groups_by_type" optionLabel="name" optionValue="id"
                            :valid="validate.validations.id_accounting_group" /> 
                          <FormInputText wrapperClass="field col-2" label="Clave" 
                            v-model="entity.id_key" 
                            :valid="validate.validations.id_key"/>
                          <FormInputText wrapperClass="field col-5" label="Nombre" 
                            v-model="entity.name" 
                            :valid="validate.validations.name"/>
                          <FormInputText wrapperClass="field col-5" label="Nombre Alterno" 
                            v-model="entity.key_name" 
                            :valid="validate.validations.key_name"/>
                        </div>
                      </Fieldset>
                    </div> 
                    </div> 
                </Fieldset>
          </div>
        </div>
        <DeleteDialog v-model="deleteDialog" @closed="deleteDialog = false" @deleted="deleted" />
        </Panel>
      </div>
    </div>
  </div>
</template>

<script>

import { AccountingLedgerAccount } from "../../../models/contabilidad/AccountingLedgerAccount";
import { FilterMatchMode } from "primevue/api";
import {
  HeaderGrid,
  Rule,
  validate,
  fillObject,
  Toast,
  ErrorToast,
} from "../../../utilities/General";
import Loader from "../../../components/general/Loader.vue";
import BasicFormToolbar from "../../../components/general/BasicFormToolbar.vue";
import DeleteDialog from "../../../components/general/DeleteDialog.vue";
import FormInputText from "../../../components/general/FormInputText.vue";
import FormDropdown from "../../../components/general/FormDropdown.vue";
import Session from "../../../mixins/sessionMixin";
import { AccountingGroup } from '../../../models/contabilidad/AccountingGroup';
import { AccountingGroupType } from '../../../models/contabilidad/AccountingGroupType';


export default {
  mixins: [Session],
  data() {
    return {
      entity: null,
      groups: [],
      groups_type: [],
      parent: null,
      entities: [],
      uploadURL: "",
      newDialog: false,
      deleteDialog: false,
      filters: {},
      selectedKey: null,
      selectedAccount: null,
      types: [
        { value: 1, text: 'ADMINISTRATIVO'},
        { value: 2, text: 'SERVICIOS'},
        { value: 3, text: 'VENTAS'},
        { value: 4, text: 'PRODUCCION'}
      ],
      rules: [
        new Rule({ name: "name" }),
        new Rule({ name: "key_name" }),
        new Rule({ name: "id_key" }),
        new Rule({ name: "id_accounting_group_type" }),
        new Rule({ name: "id_accounting_group" })
      ],
      validate: {
        valid: false,
        validations: {
          name: null,
          key_name: null,
          id_key: null,
          id_accounting_group_type: null,
          id_accounting_group: null
        },
      },
      headers: [
        new HeaderGrid("Nombre", "name"),
        new HeaderGrid("Clave", "key_name"),
      ],
      loading: false,
      items: [
                {
                  label:'Nuevo hijo',
                  icon:'pi pi-fw pi-plus',
                  action: () => this.newChild()
                },
                {
                  label:'Editar',
                  icon:'pi pi-fw pi-pencil',
                  action: () => this.editChild()
                },
                {
                  label:'Eliminar',
                  icon:'pi pi-fw pi-trash',
                  action: () => this.deleteChild()
                }
             ],
             nodes:  [],
    };
  },
  components: { Loader,BasicFormToolbar, DeleteDialog, FormInputText, FormDropdown},
  computed: {
    groups_by_type() {
      return this.groups.filter(x => x.id_accounting_group_type == this.entity.id_accounting_group_type);
    }
  },
  watch: {
    ["entity.id_accounting_group_type"]() {
      if (!this.entity.id_accounting_ledger_account_parent) {
        if (!this.entity.edited)
          this.entity.id_accounting_group = null;
        else 
          this.entity.edited = null
      }
    }
  },
  created() {
    this.parent = new AccountingLedgerAccount(this.session);
    this.entity = new AccountingLedgerAccount(this.session);
    this.initFilters();
  },
  async mounted() {
    await this.refresh();
    this.$forceUpdate();
  },
  methods: {
    newChild() {
      this.parent = this.entities.find(x => x.id == this.selectedAccount.data);
      this.entity = new AccountingLedgerAccount(this.session);
      this.entity.id_accounting_ledger_account_parent = this.parent.id;
      this.entity.id_accounting_group = this.parent.id_accounting_group;
      this.entity.id_accounting_group_type = this.parent.id_accounting_group_type;
    },
    editChild() {
      if (this.selectedAccount.parent) 
        this.parent = this.entities.find(x => x.id == this.selectedAccount.parent);
      else 
        this.parent = new AccountingLedgerAccount(this.session);
      this.entity.edited = true;
      this.entity = fillObject(this.entity, this.entities.find(x => x.id == this.selectedAccount.data));
    
    },
    deleteChild() {
      this.editChild();
      this.deleteDialog = true;
    },
    deleted() {
      this.deleteEntity();
    },
    openNew() {
      this.entity = new AccountingLedgerAccount(this.session);
      this.parent = new AccountingLedgerAccount(this.session);
    },
    hideDialog() {
      this.newDialog = false;
    },
    async save() {
      try {
        //* Validacion de form
        this.loading = true;
        this.validate = validate(this.entity, this.rules);
        if (!this.validate.valid) {
          throw "Favor de validar los campos";
        }
        //* Si el id es != 0 entonces actualizamos, si no, guardamos
        if (this.entity.id && this.entity.id > 0) {
          //* Actualizamos
          let entity = await this.entity.update();
          this.$toast.add({
            severity: "success",
            summary: "Actualizar",
            detail: "Informacion actualizada con exito",
            life: 3000,
          });
          //* Modificamos el listado pah
          let index = this.entities.findIndex((x) => x.id == this.entity.id);
          this.entities[index] = entity;
        } else {
          //* Creamos uno nuevo
          this.entity.id_accounting_ledger_account_parent = this.parent.id;
          //si la cuenta padre viene en null entonces es nivel 0
          if (this.entity.id_accounting_ledger_account_parent == null)  {
            this.entity.parent = 0;
            this.entity.level = 0;
          }
          else
          if (this.parent) 
            this.entity.level = this.parent.level + 1;
          else
            this.entity.level = 0;
          
          let entity = await this.entity.save();
          this.entities.push(entity);
          this.$toast.add(
            new Toast({
              summary: "Creacion",
              detail: "Informacion guardada con exito",
            })
          );
        }
        //* Reordenamos
        this.fillTree();
        this.entity = new AccountingLedgerAccount(this.session);
        this.parent = new AccountingLedgerAccount(this.session);
        this.newDialog = false;
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },
    edit(entity) {
      this.entity = fillObject(this.entity, entity);
      this.newDialog = true;
    },
    async deleteEntity() {
      try {
        this.loading = true;
        //* Eliminamos de la base
        await this.entity.delete();
        //* Eliminamos de la vista
        this.entities = this.entities.filter((val) => val.id !== this.entity.id);
        this.deleteDialog = false;
        //* Reordenamos
        this.fillTree();
        //* Limpiamos la entidad
        this.parent = new AccountingLedgerAccount(this.session);
        this.entity = new AccountingLedgerAccount(this.session);
        this.$toast.add({
          severity: "success",
          summary: "Eliminacion",
          detail: "Registro eliminado con exito",
          life: 3000,
        });
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },
    async exportCSV() {
      this.loading = true;
      try {
        await this.entity.export({
          id_company: this.session.company,
          id_branch: this.session.id_branch,
        }, "cuentas_contables.xlsx");
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
    onNodeSelect(x) {
      this.selectedAccount = x;
      this.$refs.menu.show(event);
    },
    uploaded() {
      this.$toast.add({
          severity: "success",
          summary: "Importar",
          detail: "Registros importados con exito",
          life: 3000,
        });
      this.refresh();
    },
    uploadError() {
      this.$toast.add(new ErrorToast("Error al intentar importar archivo"));
    },
    triggerMenu(event) {
      this.$refs.menu.hide();
      event.action.call();
      console.log(event);
    },
    fillTree() {
      let nodes = this.entities.map(x => {
          return {
            key: x.id,
            label: x.id_key + " - " + x.name,
            data: x.id,
            icon: "pi pi-fw pi-book",
            level: x.level,
            parent: x.id_accounting_ledger_account_parent
          }
        });
        this.nodes = nodes.filter(x => x.level == 0);
        // ! SOLO PUEDE LEER 8 NIVELES, OJO CON ESTO
        this.nodes.forEach(e1 => {
          e1.children = nodes.filter(x1 => x1.parent == e1.data);
          e1.children.forEach(e2 => {
            e2.children = nodes.filter(x2 => x2.parent == e2.data);
            e2.children.forEach(e3 => {
              e3.children = nodes.filter(x3 => x3.parent == e3.data);
              e3.children.forEach(e4 => {
                e4.children = nodes.filter(x4 => x4.parent == e4.data);
                e4.children.forEach(e5 => {
                  e5.children = nodes.filter(x5 => x5.parent == e5.data);
                  e5.children.forEach(e6 => {
                    e6.children = nodes.filter(x6 => x6.parent == e6.data);
                    e6.children.forEach(e7 => {
                      e7.children = nodes.filter(x7 => x7.parent == e7.data);
                      e7.children.forEach(e8 => {
                        e8.children = nodes.filter(x8 => x8.parent == e8.data);
                      });
                    });
                  });
                });
              });
            });
          });
        });
        
        this.nodes.push({
          key: nodes.id,
            label: nodes.id_key + " - " + nodes.name,
            data: nodes.id,
            icon: "pi pi-fw pi-book",
            level: nodes.level,
            parent: nodes.id_accounting_ledger_account_parent
          });
    },
    async refresh() {
      this.loading = true;
      try {
        this.entities = await this.entity.all();
        this.groups = await new AccountingGroup(this.session).all();
        this.groups_type = await new AccountingGroupType(this.session).all();
        this.fillTree();
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.table-header {
  display: flex;
  justify-content: space-between;
}

.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 960px) {
  ::v-deep(.toolbar) {
    flex-wrap: wrap;

    .button {
      margin-bottom: 0.25rem;
    }
  }
}
</style>
