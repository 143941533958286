import {
    Abstract
} from './Abstract';

export class AccountingGroup extends Abstract {
    constructor(session) {
        super('accounting/AccountingGroup', session);
        this.name = null;
        this.key_name = null;
        this.id_accounting_group_type = null;
        this.accounting_nature = null;
        this.class_name = null;
    }
}